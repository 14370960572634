import { OperatorType } from "~/pages/space/Workspaces/components/QueryBuilder/types";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { Control, Path, useFormContext } from "react-hook-form";
import {
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingOperator,
  WorkspaceSelectionConditionStringOperator,
} from "~/operations";
import {
  getIsIntKeySelected,
  getIsRatingKeySelected,
  getIsStringKeySelected,
} from "~/pages/space/Workspaces/utils";

type UseOperatorParams = {
  selectionIndex: number;
  conditionIndex: number;
  type?: OperatorType;
};

type OperatorEnums =
  | WorkspaceSelectionConditionOperator
  | WorkspaceSelectionConditionNumericOperator
  | WorkspaceSelectionConditionRatingOperator
  | WorkspaceSelectionConditionStringOperator;

type UseOperatorParamsReturn = {
  name: Path<CreateWorkspaceFormInput>;
  defaultValue: OperatorEnums;
  control: Control<CreateWorkspaceFormInput>;
  type: OperatorType;
};

export function useOperator({
  selectionIndex,
  conditionIndex,
  type: typeFromProps,
}: UseOperatorParams): UseOperatorParamsReturn {
  const { control, watch } = useFormContext<CreateWorkspaceFormInput>();
  const conditionValue = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}`,
  );

  const isIntKeySelected = getIsIntKeySelected(conditionValue?.formKey);
  const isStringKeySelected = getIsStringKeySelected(conditionValue?.formKey);
  const isRatingKeySelected = getIsRatingKeySelected(conditionValue?.formKey);

  const typeNameMap: Record<OperatorType, Path<CreateWorkspaceFormInput>> = {
    string: `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.operator`,
    condition: `selections.${selectionIndex}.conditions.${conditionIndex}.operator`,
    numeric: `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.operator`,
    rating: `selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.operator`,
  };

  const typeDefaultValueMap: Record<OperatorType, OperatorEnums> = {
    string: WorkspaceSelectionConditionStringOperator.Equal,
    condition: WorkspaceSelectionConditionOperator.And,
    numeric: WorkspaceSelectionConditionNumericOperator.Equal,
    rating: WorkspaceSelectionConditionRatingOperator.Equal,
  };

  function getOperatorType(): OperatorType {
    if (isIntKeySelected) return "numeric";
    if (isStringKeySelected) return "string";
    if (isRatingKeySelected) return "rating";

    return "condition";
  }

  const type = typeFromProps || getOperatorType();

  return {
    name: typeNameMap[type],
    defaultValue: typeDefaultValueMap[type],
    control,
    type,
  };
}

import { Path, useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import {
  useGetWorkspaceConditionPossibleValuesLazyQuery,
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingField,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import {
  getIsIntKeySelected,
  getIsRatingKeySelected,
  getIsStringKeySelected,
  isConditionComplete,
} from "~/pages/space/Workspaces/utils";
import { KeyboardEvent, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

type UseConditionRowParams = {
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
};

export function useConditionRow({
  selectionIndex,
  conditionIndex,
  isReadonly,
}: UseConditionRowParams) {
  const { watch, setValue, setError, clearErrors } =
    useFormContext<CreateWorkspaceFormInput>();
  const [searchParams] = useSearchParams();
  const [isValuesDropdownOpen, setIsValuesDropdownOpen] =
    useState<boolean>(false);
  // In case of condition values autocomplete we will ALWAYS need space mrn, not workspace mrn.
  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;
  const conditions = watch(`selections.${selectionIndex}.conditions`);
  const searchableFields: Array<
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField
    | WorkspaceSelectionConditionRatingField
  > = [
    WorkspaceSelectionConditionStringField.Platform,
    WorkspaceSelectionConditionStringField.AssetKind,
    WorkspaceSelectionConditionRatingField.Risk,
  ];

  const [
    fetchConditionValues,
    { data: conditionValuesData, loading: isConditionValuesLoading, refetch },
  ] = useGetWorkspaceConditionPossibleValuesLazyQuery();

  const conditionValue = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}`,
  );
  const conditionKey = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
  );

  const fetchMoreValues = (
    key:
      | ""
      | WorkspaceSelectionConditionStringField
      | WorkspaceSelectionConditionIntField
      | WorkspaceSelectionConditionRatingField,
    value: string,
  ) => {
    if (!key || !searchableFields.includes(key)) return;

    refetch({
      input: {
        scopeMrn: spaceMrn || "",
        ...(value ? { query: value } : {}),
        ...(getIsStringKeySelected(key)
          ? {
              stringField: key,
            }
          : {}),
        ...(getIsIntKeySelected(key)
          ? {
              intField: key,
            }
          : {}),
        ...(getIsRatingKeySelected(key)
          ? {
              ratingField: key,
            }
          : {}),
      },
    });
  };

  useEffect(() => {
    if (!conditionKey || !searchableFields.includes(conditionKey)) return;

    fetchConditionValues({
      variables: {
        input: {
          scopeMrn: spaceMrn || "",
          ...(getIsStringKeySelected(conditionKey)
            ? {
                stringField: conditionKey,
              }
            : {}),
          ...(getIsIntKeySelected(conditionKey)
            ? {
                intField: conditionKey,
              }
            : {}),
          ...(getIsRatingKeySelected(conditionKey)
            ? {
                ratingField: conditionKey,
              }
            : {}),
        },
      },
    });
  }, [conditionKey]);

  const conditionValues = (
    (conditionValue?.stringCondition?.values || []).length > 0
      ? conditionValue?.stringCondition?.values || []
      : (conditionValue?.intCondition?.values || []).length > 0
        ? conditionValue?.intCondition?.values || []
        : conditionValue?.ratingCondition?.values || []
  ).map((v) => String(v));

  const handleKeyChange = (
    name: Path<CreateWorkspaceFormInput>,
    value:
      | WorkspaceSelectionConditionStringField
      | WorkspaceSelectionConditionIntField
      | WorkspaceSelectionConditionRatingField,
  ) => {
    setValue(name, value, { shouldDirty: true });
  };

  const conditionKeys = [
    {
      label: "INFRASTRUCTURE",
      values: [
        {
          label: "Platform",
          value: WorkspaceSelectionConditionStringField.Platform,
        },
        {
          label: "Platform version",
          value: WorkspaceSelectionConditionStringField.PlatformVersion,
        },
      ],
    },
    {
      label: "RISK & FINDINGS",
      values: [
        /*{
          label: "Risk Score",
          value: WorkspaceSelectionConditionIntField.RiskScore,
        },*/
        {
          label: "Risk rating",
          value: WorkspaceSelectionConditionRatingField.Risk,
        },
      ],
    },
    {
      label: "METADATA",
      values: [
        {
          label: "Asset name",
          value: WorkspaceSelectionConditionStringField.AssetName,
        },
        {
          label: "Kind",
          value: WorkspaceSelectionConditionStringField.AssetKind,
        },
      ],
    },
  ];

  const isKeySelected = !!conditionKey;
  const isStringKeySelected = getIsStringKeySelected(conditionKey);
  const isRatingKeySelected = getIsRatingKeySelected(conditionKey);

  const conditionValuesFieldName:
    | `selections.${number}.conditions.${number}.stringCondition.values`
    | `selections.${number}.conditions.${number}.intCondition.values`
    | `selections.${number}.conditions.${number}.ratingCondition.values` =
    isStringKeySelected
      ? `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`
      : isRatingKeySelected
        ? `selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.values`
        : `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`;

  const isFirstCondition = conditionIndex === 0;

  const conditionOperator = WorkspaceSelectionConditionOperator.And;

  const getSelectedKeyLabel = () => {
    for (const group of conditionKeys) {
      const targetValue = group.values.find((v) => v.value === conditionKey);
      if (targetValue) {
        return targetValue.label;
      }
    }
    return "";
  };

  const selectedConditionKeyLabel = getSelectedKeyLabel();

  const handleConditionValueRemove = (value: string) => {
    if (isReadonly) return;

    const newValues = conditionValues.includes(value)
      ? conditionValues.filter((v) => v !== value)
      : [...conditionValues, value];

    setValue(conditionValuesFieldName, newValues, { shouldDirty: true });
    const invalidConditions = conditions.filter(
      (condition) => !isConditionComplete(condition),
    );

    invalidConditions.forEach((_) => {
      setError(conditionValuesFieldName, {
        message: "This condition is incomplete. Please add a selection.",
      });
    });
  };

  const handleConditionKeyRemove = () => {
    if (isReadonly) return;
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
      "",
      { shouldDirty: true },
    );
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`,
      [],
      { shouldDirty: true },
    );
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`,
      [],
      { shouldDirty: true },
    );
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.ratingCondition.values`,
      [],
      { shouldDirty: true },
    );
    clearErrors(conditionValuesFieldName);
  };

  const handleValuesItemClick = (value: unknown) => {
    const castedCurrentValues = conditionValues.map((cv) => String(cv));

    const isValueSelected = castedCurrentValues.includes(value as string);
    if (isValueSelected) {
      setValue(
        conditionValuesFieldName,
        castedCurrentValues.filter((cv) => cv !== value),
        { shouldDirty: true },
      );
    } else {
      setValue(
        conditionValuesFieldName,
        [...castedCurrentValues, value as string],
        { shouldDirty: true },
      );
    }
    clearErrors(conditionValuesFieldName);
  };

  const handleDropdownSearchFieldChange: TextFieldProps["onChange"] = debounce(
    (e) => {
      fetchMoreValues(conditionKey, e.target.value);
    },
    400,
  );

  const handleDropdownSearchFieldKeyUp = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter") {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        handleValuesItemClick(e.target.value);
        e.target.value = "";
      }
    }
  };

  const handleValuesDropdownOpen = () => {
    setIsValuesDropdownOpen(true);
  };

  const handleValuesDropdownClose = () => {
    fetchMoreValues(conditionKey, "");
    setIsValuesDropdownOpen(false);
  };

  return {
    showIncludeLabel: isFirstCondition,
    showConditionOperator: !isFirstCondition,
    showConditionKeyLabel: isKeySelected,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions: (
      conditionValuesData?.workspaceConditionPossibleValues.suggestions || []
    ).map((option) => ({
      ...(option.__typename === "WorkspaceConditionStringValueSuggestion"
        ? {
            value: option.stringValue,
            label: option.stringDisplayName,
          }
        : option.__typename === "WorkspaceConditionIntValueSuggestion"
          ? {
              value: String(option.intValue),
              label: option.intDisplayName,
            }
          : {
              value: option.ratingValue,
              label: option.ratingDisplayName,
            }),
    })),
    selectedConditionKeyLabel,
    conditionOperator,
    conditionValuesFieldName,
    handleConditionValueRemove,
    handleConditionKeyRemove,
    handleKeyChange,
    handleValuesItemClick,
    handleDropdownSearchFieldKeyUp,
    handleDropdownSearchFieldChange,
    isConditionValuesLoading,
    isValuesDropdownOpen,
    handleValuesDropdownOpen,
    handleValuesDropdownClose,
  };
}

import { OperatorDropdown } from "~/pages/space/Workspaces/components/QueryBuilder/Operator/OperatorDropdown";
import { Controller } from "react-hook-form";
import { useOperator } from "~/pages/space/Workspaces/components/QueryBuilder/Operator/hooks/useOperator";
import { OperatorType } from "~/pages/space/Workspaces/components/QueryBuilder/types";

type OperatorProps = {
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
  type?: OperatorType;
};

export const Operator = ({
  isReadonly,
  conditionIndex,
  selectionIndex,
  type: typeFromProps,
}: OperatorProps) => {
  const { name, defaultValue, control, type } = useOperator({
    selectionIndex,
    conditionIndex,
    type: typeFromProps,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <OperatorDropdown
          type={typeFromProps || type}
          isReadonly={isReadonly}
          defaultValue={defaultValue}
          {...field}
        />
      )}
    />
  );
};

import { Fragment } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button,
  Tooltip,
} from "@mui/material";
import { Asset } from "~/pages/inventory/asset/types";
import {
  configurationTableSx,
  extractItemKey,
  extractItemValue,
} from "./asset-configuration-section";
import { OpenInNewIcon } from "./icons";
import { Clamp } from "./clamp/clamp";

type AssetOverviewGroup = Asset["overviewGroups"][0];

type OverviewGroups = {
  labelsGroup: AssetOverviewGroup[];
  otherGroups: AssetOverviewGroup[];
  externalLink?: AssetOverviewUrValue;
};

type AssetOverviewItem = AssetOverviewGroup["items"][0];

type AssetOverviewUrValue = Extract<
  AssetOverviewItem["values"][0],
  { __typename: "AssetOverviewURLValue" }
>;

export type ConfigurationAssetOverviewProps = {
  asset: Asset;
};

export function ConfigurationAssetOverview({
  asset,
}: ConfigurationAssetOverviewProps) {
  const overviewGroups = asset.overviewGroups.filter(
    (g) => g.title !== "Platform overview",
  );

  const extractLink = (item: AssetOverviewItem) => {
    switch (item.values[0].__typename) {
      case "AssetOverviewURLValue":
        return item.values[0].url;
      default:
        return undefined;
    }
  };

  const { labelsGroup, externalLink, otherGroups } =
    asset.overviewGroups.reduce<OverviewGroups>(
      (acc, group) => {
        if (
          group.title.toLowerCase().includes("labels") ||
          group.title.toLowerCase().includes("tags")
        ) {
          acc.labelsGroup.push(group);
        } else if (group.key === "external-link") {
          if (group.items.length === 1 && group.items[0].values.length === 1) {
            const value = group.items[0].values[0];
            if (value.__typename === "AssetOverviewURLValue") {
              acc.externalLink = value;
            }
          }
        } else {
          if (group.key === "asset-overview-scan-overview-group") {
            acc.otherGroups.push(group);
          }
        }
        return acc;
      },
      { labelsGroup: [], externalLink: undefined, otherGroups: [] },
    );

  if (labelsGroup.length === 0 && asset.labels.length > 0) {
    const items = asset.labels.map((label) => {
      return {
        key: label.key,
        value: label.value,
      };
    });

    labelsGroup.push({
      title: "labels",
      key: "labels",
      items: [
        {
          key: "labels",
          name: "labels",
          sources: null,
          values: [
            {
              dictValue: items,
              __typename: "AssetOverviewDictValue",
            },
          ],
        },
      ] as AssetOverviewItem[],
      __typename: "AssetOverviewGroup",
    });
  }

  const overviewPolicyResults = (
    <TableContainer>
      <Table
        sx={{ tableLayout: "fixed", "& tr:hover": { background: "inherit" } }}
      >
        {otherGroups.map((group) => {
          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                    className="table-title"
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={configurationTableSx}>
                {group?.items.map((item) => {
                  const key = extractItemKey(item);
                  const value = extractItemValue(item);
                  const link = extractLink(item);
                  return (
                    <Fragment key={item.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {key}
                        </TableCell>
                        {link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <Link target="_blank" rel="noreferrer" href={link}>
                              {value}
                            </Link>
                          </TableCell>
                        )}
                        {!link && (
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            {value}
                          </TableCell>
                        )}
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}
        {labelsGroup.map((group) => {
          const value = group.items[0].values[0];
          const dictValue =
            value.__typename === "AssetOverviewDictValue"
              ? value.dictValue
              : undefined;

          return (
            <Fragment key={group.key}>
              <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <TableRow
                  sx={{
                    "&:hover": {
                      pointerEvents: "none",
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      pl: 0,
                      pb: 1,
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.background.lightest}`,
                      textTransform: "uppercase",
                    }}
                  >
                    {group.title}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={configurationTableSx}>
                {dictValue?.map((value) => {
                  return (
                    <Fragment key={value.key}>
                      <TableRow>
                        <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                          {value.key}
                        </TableCell>
                        <Tooltip title={value.value} placement="top" arrow>
                          <TableCell className={"width-50"} sx={{ pl: 0 }}>
                            <Clamp sx={{ cursor: "pointer" }}>
                              {value.value}
                            </Clamp>
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          );
        })}
        {externalLink && (
          <TableBody sx={configurationTableSx}>
            <TableRow>
              <TableCell className={"width-100"} sx={{ pl: 0 }}>
                <Button
                  href={externalLink.url}
                  target="_blank"
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                >
                  {externalLink.displayValue}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  const overviewPolicyNoResults = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        pt: 2,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        No Data
      </Typography>
    </Box>
  );

  return (
    <Box>
      {overviewGroups.length > 0 || labelsGroup.length > 0
        ? overviewPolicyResults
        : overviewPolicyNoResults}
    </Box>
  );
}

import {
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionRatingOperator,
  WorkspaceSelectionConditionStringOperator,
} from "~/operations";
import { OperatorType } from "~/pages/space/Workspaces/components/QueryBuilder/types";

type UseOperatorDropdownProps = {
  type: OperatorType;
};

export function useOperatorDropdown({ type }: UseOperatorDropdownProps) {
  const conditionsOperators: Exclude<
    WorkspaceSelectionConditionOperator,
    WorkspaceSelectionConditionOperator.Unknown
  >[] = [
    WorkspaceSelectionConditionOperator.And,
    WorkspaceSelectionConditionOperator.AndNot,
  ];

  const conditionsOperatorLabelMap: Record<
    Exclude<
      WorkspaceSelectionConditionOperator,
      WorkspaceSelectionConditionOperator.Unknown
    >,
    string
  > = {
    [WorkspaceSelectionConditionOperator.And]: "AND",
    [WorkspaceSelectionConditionOperator.AndNot]: "AND NOT",
  };

  const numericOperators: Exclude<
    WorkspaceSelectionConditionNumericOperator,
    WorkspaceSelectionConditionNumericOperator.Unknown
  >[] = [
    WorkspaceSelectionConditionNumericOperator.Equal,
    WorkspaceSelectionConditionNumericOperator.NotEqual,
    WorkspaceSelectionConditionNumericOperator.Gt,
    WorkspaceSelectionConditionNumericOperator.Lt,
  ];

  const numericOperatorLabelMap: Record<
    Exclude<
      WorkspaceSelectionConditionNumericOperator,
      WorkspaceSelectionConditionNumericOperator.Unknown
    >,
    string
  > = {
    [WorkspaceSelectionConditionNumericOperator.Equal]: "IS",
    [WorkspaceSelectionConditionNumericOperator.NotEqual]: "NOT",
    [WorkspaceSelectionConditionNumericOperator.Gt]: ">=",
    [WorkspaceSelectionConditionNumericOperator.Lt]: "<=",
  };

  const stringOperators: Exclude<
    WorkspaceSelectionConditionStringOperator,
    WorkspaceSelectionConditionStringOperator.Unknown
  >[] = [
    WorkspaceSelectionConditionStringOperator.Equal,
    WorkspaceSelectionConditionStringOperator.NotEqual,
    WorkspaceSelectionConditionStringOperator.Contains,
  ];

  const stringOperatorLabelMap: Record<
    Exclude<
      WorkspaceSelectionConditionStringOperator,
      WorkspaceSelectionConditionStringOperator.Unknown
    >,
    string
  > = {
    [WorkspaceSelectionConditionStringOperator.Equal]: "IS",
    [WorkspaceSelectionConditionStringOperator.NotEqual]: "NOT",
    [WorkspaceSelectionConditionStringOperator.Contains]: "CONTAINS",
  };

  const ratingOperators: Exclude<
    WorkspaceSelectionConditionRatingOperator,
    WorkspaceSelectionConditionRatingOperator.Unknown
  >[] = [
    WorkspaceSelectionConditionRatingOperator.Equal,
    WorkspaceSelectionConditionRatingOperator.NotEqual,
  ];

  const ratingOperatorsLabelMap: Record<
    Exclude<
      WorkspaceSelectionConditionRatingOperator,
      WorkspaceSelectionConditionRatingOperator.Unknown
    >,
    string
  > = {
    [WorkspaceSelectionConditionRatingOperator.Equal]: "IS",
    [WorkspaceSelectionConditionRatingOperator.NotEqual]: "NOT",
  };

  switch (type) {
    case "condition":
      return {
        operators: conditionsOperators as string[],
        operatorsLabelMap: conditionsOperatorLabelMap as Record<string, string>,
      };
    case "numeric":
      return {
        operators: numericOperators as string[],
        operatorsLabelMap: numericOperatorLabelMap as Record<string, string>,
      };
    case "string":
      return {
        operators: stringOperators as string[],
        operatorsLabelMap: stringOperatorLabelMap as Record<string, string>,
      };
    case "rating":
      return {
        operators: ratingOperators as string[],
        operatorsLabelMap: ratingOperatorsLabelMap as Record<string, string>,
      };
  }
}
